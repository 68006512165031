import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL, API_TOKEN, API_BASE_URL } from "./apiSettings";
import axios from "axios";

export const storeUser = (data) => {
    localStorage.setItem('user', JSON.stringify({
        id: data.user.id,
        jwt: data.jwt,
        expiresAt: Date.now() + 3600000,
    }))
};

export const userData = () => {
    const stignifiedUser = localStorage.getItem('user') || '""';
    return JSON.parse(stignifiedUser || {});
};

export const Protector = ({ Component }) => {
    const navigate = useNavigate();

    const { jwt } = userData();

    useEffect(() => {
        if (!jwt) {
            navigate('/connexion-inscription');
        }
    }, [navigate, jwt]);

    return <Component />;
};

export const isLoggedIn = () => {
    const { jwt } = userData();
    return !!jwt;
};

export const getUserData = async (userId) => {
    try {
      const response = await fetch(`${API_URL}users/${userId}?populate=*`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${API_TOKEN}`
        },
      });

      if (response.ok) {
        const data = await response.json();
        data.profilePicture = API_BASE_URL+data.profile_picture.formats.thumbnail.url
        // console.log(data);
        return data;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
};

export const updateUserData = async (userId, data) => {
    try {
      const response = await fetch(`${API_URL}users/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${API_TOKEN}`
        },
        body: data,
      });
    //   console.log(response);
      if (response.ok) {
        const data = await response.json();
        // console.log(data);
        return true;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
};

export const getCities = async () => {
  try {
    const response = await fetch('https://geo.api.gouv.fr/departements/971/communes', {
      method: 'GET',
      // headers: {
      //   'Content-Type': 'application/json'
      // },
    });

    if (response.ok) {
      const data = await response.json();
      const formattedCities = data.map(city => ({
        label: city.nom,
        value: city.nom
      }));
      return formattedCities;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export const createBankAccount = async (data) => {
    try {
      const response = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}bank-accounts`,
          data: data,
          headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
              "Content-Type": "application/json"
          },
      });
      // console.log(response);
      if (response.data.data) {
        // const data = await response.data.data;
        // console.log(data);
        return true;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
};

export const updateBankAccount = async (accountId, data) => {
    try {
      const response = await axios({
          method: 'PUT',
          url: `${process.env.REACT_APP_API_URL}bank-accounts/${accountId}`,
          data: data,
          headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
              "Content-Type": "application/json"
          },
      });
      // console.log(response);
      if (response.data.data) {
        // const data = await response.json();
        // console.log(true);
        return true;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
};

export const deleteUser = async (userId) => {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}users-permissions/delete-user?id=${userId}`, {
          headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${process.env.REACT_APP_API_TOKEN}`
          },
      });

      if (response.status === 200) {
          return true;
      } else {
          return null;
      }
  } catch (error) {
      console.log(error);
      return null;
  }
};